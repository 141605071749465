<template>
  <div class="flex justify-center mb-10">
    <div class="w-full lg:w-2/3 p-4 md:p-6">
      <h2 class="text-2xl md:text-3xl font-bold text-center dark:text-green-400 my-10">
        قوانین و شرایط استفاده از خدمات
      </h2>

      <div class="bg-gray-50 dark:bg-gray-800 leading-loose shadow rounded p-4 md:p-6 space-y-4">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoService from "@/services/InfoService";

export default {
  data() {
    return {
      content: "",
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      InfoService.getPolicy()
        .then(({ data }) => {
          this.content = data;
        });
    },
  },
};
</script>

<style>
p {
  margin-bottom: 1rem;
}

ol {
  list-style-type: disc;
  list-style-position: inside;
}

ol ul {
  margin-right: 2rem;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 0.5rem;
}

li {
  margin-bottom: 0.5rem;
}
</style>
